import React from 'react';
import Alert from 'react-bootstrap/Alert';

import style from './CustomAlert.module.css';

function CustomAlert({show, setShow, variant = "success", text, title, children}) {
	return (
		show &&
		<section className={style.customAlertContainer}>
			<Alert
				dismissible
				variant={variant}
				onClose={() => setShow(false)}>
				<Alert.Heading>{title}</Alert.Heading>
				<p>{text}</p>
				{children &&
					<div className={style.customAlertChildrenContainer}>
						{children}
					</div>}
			</Alert>
		</section>
	);
}

export default CustomAlert;