import React from "react";
import {
    QUEUE_NAME_BSMH_ESCALATIONS,
    QUEUE_TYPE_DEFAULT,
    QueueIncomingLabel
} from "../../utils/constant";

const ContactPreviewLabel = ({incomingContact}) => {

    const getChatContactPreviewLabel = () => {
        let result;
        let queueType = incomingContact?.queueType?.value;

        if (incomingContact?.messageBeforeTransfer?.value) {
            try {
                const parsedMessageBeforeTransfer = JSON.parse(incomingContact.messageBeforeTransfer.value);
                if (parsedMessageBeforeTransfer.queueType !== undefined && parsedMessageBeforeTransfer.queueType !== null) {
                    queueType = parsedMessageBeforeTransfer.queueType;
                }
            } catch (e) {}
        }

        // *** queueType can be '0'
        if (queueType !== undefined && queueType !== null) {
            result = QueueIncomingLabel.byType[parseInt(queueType, 10)];
        }

        const queueName = incomingContact?.queueName;
        let _result
        let isIncoming = false;
        if (queueName) {
            _result = QueueIncomingLabel.byName[queueName];
            if (!_result) {
                _result = getSpecialLabelForQueue(incomingContact);
            }
            if (_result) {
                result = _result;
            }
        } else  {
            isIncoming = true
        }

        // default labels
        if (!result || result === QueueIncomingLabel.byType[QUEUE_TYPE_DEFAULT] || isIncoming) {
            //result = QueueIncomingLabel.byType[QUEUE_TYPE_DEFAULT];
            result = <>
                <font style={{fontSize: "12px"}}>
                    {incomingContact?.chatTransfer?.value === 'true' ? 'Incoming transfer:' : 'Incoming new chat:'}
                </font><br/>
                <font style={{fontSize: "12px"}}>{queueName ? queueName : "N/A"}</font>
            </>;
        }

        return result;
    }

    const getSpecialLabelForQueue = (incomingContact) => {
        if (incomingContact.queueName === QUEUE_NAME_BSMH_ESCALATIONS) {
            const startQueueName = incomingContact?.startQueueName?.value.toLowerCase();
            for (let qName in QueueIncomingLabel.byStartName) {
                if (startQueueName === qName.toLowerCase()) {
                    return QueueIncomingLabel.byStartName[qName];
                }
            }
        }
        return false;
    }

    return <div className="chat-contact-preview">
        {getChatContactPreviewLabel()}
    </div>
};

export default ContactPreviewLabel;