import React from 'react';
//import { createTimeModel, useTimeModel } from "react-compound-timer";
import Timer from "react-compound-timer";

function ExTimer({initialTime, startImmediately}) {
    // const timer = createTimeModel({
    //     initialTime: initialTime,
    // });
    //
    // const { value } = useTimeModel(timer);
    //
    // return (
    //     <>
    //         {value.min}:{value.s.toString().padStart(2, '0')}
    //     </>
    // );

    return (
        <Timer
            initialTime={initialTime ?? 0}
            startImmediately={startImmediately ?? true}
        >
            {() => (
                <>
                    <Timer.Minutes/>:
                    <Timer.Seconds formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}/>
                </>
            )}
        </Timer>
    );
}

export default ExTimer