import {sendErrorLog} from "../../utils/logger";
import {AlertErrors, AgentState} from "../../utils/constant";
import {checkExceptionDictionaryQueueName, prepareQueueName} from '../../utils/helpers.js'

export const connect = window.connect;

export const initConnect = ({containerDiv, ccpUrl, loginUrl, ringtoneUrl}) => {
    connect.core.initCCP(containerDiv, {
        ccpUrl: ccpUrl,
        loginUrl: loginUrl,
        loginPopup: true,               // optional, defaults to `true`
        loginPopupAutoClose: true,      // optional, defaults to `true`
        loginOptions: {                 // optional, if provided opens login in new window
            autoClose: true,            // optional, defaults to `false`
            height: 600,                // optional, defaults to 578
            width: 400,                 // optional, defaults to 433
            top: 0,                     // optional, defaults to 0
            left: 0                     // optional, defaults to 0
        },
        region: "us-east-1",       // REQUIRED for `CHAT`, optional otherwise
        chat: {                    // optional
            disableRingtone: !ringtoneUrl,
            ringtoneUrl: ringtoneUrl // optional
        },
        ccpAckTimeout: 3000, //optional, defaults to 3000 (ms)
        ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
        ccpLoadTimeout: 5000 //optional, defaults to 5000 (ms)
    });
};

export const setChatSessionGlobalConfig = () => {
    connect.ChatSession.setGlobalConfig({
        loggerConfig: { // optional, the logging configuration. If omitted, no logging occurs
            logger: { // optional, a logger object implementation
                debug: (msg) => console.debug(msg), // REQUIRED, can be any function
                info: (msg) => console.info(msg), // REQUIRED, can be any function
                warn: (msg) => console.warn(msg), // REQUIRED, can be any function
                error: (msg) => {
                    console.error('Chat session error', msg);
                    sendErrorLog('Chat session error', {message: msg});
                } // REQUIRED, can be any function
            },
            level: connect.ChatSession.LogLevel.INFO, // optional, defaults to: `connect.ChatSession.LogLevel.INFO`
        },
        region: "us-east-1", // optional, defaults to: "us-west-2"
    });
    // ToDo: Remove when CSM code in Amazon library will be fixed
    // Fix empty errors like 'ERROR'
    try {
        console.log('^ Disable CSM functions');
        connect.csmService.addLatencyMetric = () => {};
        connect.csmService.addCountAndErrorMetric = () => {};
        connect.csmService.addCountMetric = () => {};
        connect.csmService.addAgentCountMetric = () => {};
    } catch (e) {
        sendErrorLog('Try to disable CSM functions', {message: e.message});
    }
};

export const createAgentChatSession = (contact) => {
    const cnn = contact.getConnections().find(cnn => cnn.getType() === connect.ConnectionType.AGENT);
    return connect.ChatSession.create({
        chatDetails: cnn.getMediaInfo(), // REQUIRED
        options: { // REQUIRED
            region: "us-east-1", // REQUIRED, must match the value provided to `connect.core.initCCP()`
        },
        type: connect.ChatSession.SessionTypes.AGENT, // REQUIRED
        websocketManager: connect.core.getWebSocketManager(), // REQUIRED
        disableCSM: true
    });
};

export const LoginPopup = (loginUrl, loginOptions) => {
    try {
        connect.getLog().warn("Open the login page in popup.").sendInternalLogToServer();
        connect.core.getPopupManager().clear(connect.MasterTopics.LOGIN_POPUP);
        connect.core.loginWindow = connect.core.getPopupManager().open(loginUrl, connect.MasterTopics.LOGIN_POPUP, loginOptions);
    } catch (e) {
        sendErrorLog('Try popup login window (1)', {message: e.message});
        connect.getLog().error("Unable to open the login popup.").withException(e).sendInternalLogToServer();
    }

    if (connect.core.iframeRefreshTimeout == null) {
        const conduit = connect.core.upstream;
        try {
            conduit.onUpstream(connect.EventType.ACKNOWLEDGE, function () {
                clearTimeout(connect.core.iframeRefreshTimeout);
                connect.core.iframeRefreshTimeout = null;
                connect.core.getPopupManager().clear(connect.MasterTopics.LOGIN_POPUP);
                if ((loginOptions && loginOptions.autoClose) && connect.core.loginWindow) {
                    connect.core.loginWindow.close();
                    connect.core.loginWindow = null;
                }
            });
            //connect.core._refreshIframeOnTimeout(params, containerDiv);
        } catch (e) {
            sendErrorLog('Try popup login window (2)', {message: e.message});
            connect.getLog().error("Error occurred while refreshing iframe").withException(e).sendInternalLogToServer();
        }
    }
};

export const installConnectFixes = () => {
    //**************************
    // TypeError: Cannot read properties of null (reading 'postMessage')
    connect.WindowIOStream.prototype.send = function (message) {
        this && this.output && this.output.postMessage(message, this.domain);
    };
};

export const getContactData = (contact, additionalData) => {
    let data = {
        connection: contact,
        connected: false,
        state: {
            type: 'ended'
        },
        initial: null,
        attributes: {},
        additionalData: additionalData || {}
    };
    try {
        data.connected = contact.isConnected();
        data.state = contact.getState();
        data.initial = contact.getOriginalContactId();
        data.attributes = contact.getAttributes();
    } catch (e) {
        sendErrorLog("Can't get contact data.", {message: e.message, contact: contact});
    }
    return data;
};

export const getContactQueueName = (contact, isSeparateName) => {
    let queueName = '';
    try {
        queueName = (contact.connection) ?
            contact.connection.getQueue().name
            :
            contact.getQueue().name;

        if (isSeparateName) {
            if (!checkExceptionDictionaryQueueName(queueName) && queueName.includes('-')) {
                queueName = prepareQueueName(queueName)
            }
        }
    } catch (e) {
        queueName = 'N/A';
    }
    return queueName;
};


export const getCurrentAgentInfo = () => {
    let agentConfig = {};
    try {
        agentConfig = window.myCPP?.agent?.getConfiguration();
    } catch (e) {
        sendErrorLog("Can't get current agent info.", {message: e.message});
    }
    return {
        username: agentConfig?.username,
        profile: agentConfig?.routingProfile.name
    };
};

export const sendConnectErrorLog = (message, data) => {
    try {
        sendErrorLog(message, {
            component: data.component,
            level: data.level,
            text: data.text,
        });
    } catch (e) {
        sendErrorLog("Can't send Connect Error Log.", {message: e.message});
    }
}

const watchConnectInternalLogs = () => {
    let lastIdx = 0;
    let errCount = 0;
    const parseInternalLogs = () => {
        if (errCount > 3) {
            return;
        }
        if (connect) {
            try {
                const logs = connect.getLog()._logs;
                const logsLength = logs.length;
                if (logsLength && lastIdx < logsLength - 1) {
                    for (let idx = lastIdx; idx < logsLength; idx++) {
                        const logItem = logs[idx];
                        if (logItem.level === 'ERROR' && logItem.text) {
                            sendErrorLog("Connect internal log: " + logItem.text, {
                                time: logItem.time.toString(),
                                data: JSON.stringify(logItem.object)
                            });
                        }
                    }
                    lastIdx = logsLength - 1;
                }
            } catch (e) {
                errCount++;
                sendErrorLog("Can't parse Connect Internal Logs.", {message: e.message});
            }
        }
    }
    if (!window.watchConnectInternalLogsIntervalId) {
        window.watchConnectInternalLogsIntervalId = setInterval(parseInternalLogs, 5000);
    }
}

export const subscribeToConnectLogs = (errorStateCallback) => {
    watchConnectInternalLogs();
    try {
        const runCallback = (alert) => {
            if (errorStateCallback && typeof errorStateCallback === 'function') {
                errorStateCallback(alert || AlertErrors.COMMON);
            }
        };
        const eventBus = connect.core.getEventBus();
        eventBus.subscribe(connect.EventType.LOG, (data) => {
            if (data.level === connect.LogLevel.INFO) {
                if (data.text.includes('Network offline')) {
                    sendConnectErrorLog('Network offline', data);
                    runCallback(AlertErrors.NETWORK);
                }
            }
            const logLevels = [
                connect.LogLevel.WARN,
                connect.LogLevel.ERROR,
                connect.LogLevel.CRITICAL
            ];
            if (logLevels.indexOf(data.level) !== -1
                && data.text
                && data.text.trim() !== 'ADVANCED_LOG'
            ) {
                const networkErrorStrings = [
                    'WebSocketManager Error',
                    'getWebSocketUrl failed',
                    'ChatClient error when sending ',
                    'ChatClient error when getting ',
                    // 'ChatClient error when sending event',
                    // 'ChatClient error when sending message',
                    // 'ChatClient error when getting transcript',
                    'API request failed',
                    'Failed to fetch webSocket connection configuration',
                    'Failed to handle AWS API request',
                    'Failed to get agent data',
                    'Failed to fetch agent configuration data',
                ];
                if (networkErrorStrings.some(errorString => data.text.includes(errorString))) {
                    if (data.text !== "'createTransport' API request failed") {
                        runCallback(AlertErrors.NETWORK);
                    }
                }
                sendConnectErrorLog(data.text, data);
            }
        });
    } catch (e) {
        sendErrorLog('Can\'t subscribe to Connect logs', {message: e.message});
    }
};

export const prepareAgentState = (agent, state) => {
    try {
        const agentStates = agent.getAgentStates();
        switch (state) {
            case AgentState.ONLINE:
                return agentStates.filter(tState => {
                    return (tState.type === connect.AgentStateType.ROUTABLE
                        && ['available', 'online'].includes(tState.name.toLowerCase())
                    );
                })[0];
            case AgentState.OFFLINE:
                return agentStates.filter(tState => {
                    return (tState.type === connect.AgentStateType.OFFLINE);
                })[0];
            case AgentState.AWAY:
                return agentStates.filter(tState => {
                    return (tState.type === connect.AgentStateType.NOT_ROUTABLE
                        && tState.name.toLowerCase() === AgentState.AWAY
                    );
                })[0];
        }
    } catch (e) {
        sendErrorLog('Error: Can\'t prepare agent state "' + state + '"', {message: e.message});
    }

    return false;
}
