import {ThreeDots as LoaderThreeDots} from "react-loader-spinner";
import React from "react";

const PopupSpinner = (props) => (
    <div className="text-center" {...props}>
        <LoaderThreeDots
            color="#d7d7d7"
            height={60}
            width={60}
            timeout={0}
            wrapperStyle={
              {
                justifyContent: "center"
              }
            }
        />
    </div>
);

export default PopupSpinner;