import { useEffect, useState } from 'react';

export const useCommonAnswers = (commonAnswers, currentQueueName) => {
	const [renderComponent, setRenderComponent] = useState(false);

	useEffect(() => {
		const result = commonAnswers
			&& currentQueueName
			&& commonAnswers.enable
			&& commonAnswers.queueTemplates[currentQueueName];
		setRenderComponent(result);
	}, [commonAnswers, currentQueueName]);

	return renderComponent;
};