import React from "react";

const ChatTransferNotify = () => (
    <>
        {/*ToDo:Rework*/}
        <i className="fas fa-check-circle"/>
        <span>Start chat transferring ...</span>
    </>
);

export default ChatTransferNotify;