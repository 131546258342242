import { useEffect, useMemo, useState } from 'react';
import {generateLoginUrl, getAgentChatConfigFromAPI, getAgentChatConfigFromStorage} from "../configs/configHelper";
import {AppState} from "../utils/constant";

export const useAgentChatConfiguration = (props, callbackAppState) => {
	const [agentChatConfiguration, setAgentChatConfiguration] = useState({});

	const {config, clientId, orgId, loginUrl} = props;

	const checkConfigVar = () => {
		return (config && Object.keys(config)?.length > 0);
	}

	const finalizeAgentChatConfig = (_config) => {
		let result = _config || {};
		result.xApiUrl = process.env.REACT_APP_AMAZON_API_URL;
		result.xApiKey = process.env.REACT_APP_AMAZON_API_KEY;
		if (!loginUrl) {
			result.loginUrl = generateLoginUrl(
				result.xApiUrl,
				result.instanceId
			);
		} else {
			result.loginUrl = insertInstanceIdToUrl(loginUrl, result.instanceId);
		}
		return result;
	}

	useEffect(() => {
		if (checkConfigVar()) {
			setAgentChatConfiguration(finalizeAgentChatConfig(config));
		}
	}, []);

	useEffect(() => {
		if (checkConfigVar()) {
			return;
		}
		callbackAppState(AppState.RELOAD);
		const getCfg = () => {
			if (props.configApi) {
				return getAgentChatConfigFromAPI(props.configApi, clientId);
			} else {
				return getAgentChatConfigFromStorage(clientId ? clientId : orgId);
			}
		}
		getCfg()
			.then(result => {
				setAgentChatConfiguration(finalizeAgentChatConfig(result));
			})
			.catch(() => {
				callbackAppState(AppState.ERROR);
			});
	}, [clientId, orgId]);

	const insertInstanceIdToUrl = (url, instanceId) => {
		const parsedUrl = new URL(url);
		const redirectUri = parsedUrl.searchParams.get('redirect_uri');
		if (redirectUri) {
			const redirectUrl = new URL(redirectUri);
			redirectUrl.searchParams.set('instance_id', instanceId);
			parsedUrl.searchParams.set('redirect_uri', redirectUrl.toString());
		} else if (parsedUrl.searchParams.get('instance_id')) {
			parsedUrl.searchParams.set('instance_id', instanceId);
		} else {
			parsedUrl.searchParams.append('instance_id', instanceId);
		}

		return parsedUrl.toString();
	};

	return agentChatConfiguration;
}
