import React from "react";

export const CustomerInfoMessage = (props) => {
    const {data, messageTime} = props;
    const {name, DOB, phone} = data;

    return <>
        {
            DOB || phone ?
                <div className={"row message-left"}>
                    <div className="col-md-9">
                        <p>
                            Customer info:<br/>
                            Name: {name || 'Customer'}<br/>
                            Date of Birth or Responsible Person Number: {DOB || '-'}<br/>
                            {phone ? "Phone number: " + phone : ""}
                        </p>
                        <span className={"message-time "}>
                            {messageTime}
                        </span>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
                :
                ''
        }
    </>;
}
