import {datadogLogs} from "@datadog/browser-logs";
import {getCurrentAgentInfo} from "../services/Amazon/Connect";

export const initLogger = () => {
	try {
		datadogLogs.init({
			clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
			site: 'datadoghq.com',
			forwardErrorsToLogs: true,
			sampleRate: 100,
			service: 'agent-chat-app',
			env: process.env.REACT_APP_ENV,
			silentMultipleInit: true,
		});
		window.CHAT_DD_LOGS = datadogLogs;
	} catch (e) {
		console.error('Can\'t initiate agent chat logger. Error: ' + e.message);
	}
};

export const getBaseLogData = () => {
	return {
		agent: getCurrentAgentInfo()
	};
}

export const sendErrorLog = (message, context) => {
	let logData = getBaseLogData();
	if (context) {
		logData = {...logData, ...context};
	}
	if (window.CHAT_DD_LOGS) {
		try {
			window.CHAT_DD_LOGS.logger.error(message, {log_data: logData});
		} catch (e) {
			console.error('Can\'t send agent chat error log. Error: ' + e.message);
		}
	}
};

export const sendInfoLog = (message, context) => {
	let logData = getBaseLogData();
	if (context) {
		logData = {...logData, ...context};
	}
	if (window.CHAT_DD_LOGS) {
		try {
			window.CHAT_DD_LOGS.logger.info(message, {log_data: logData});
		} catch (e) {
			console.error('Can\'t send agent chat info log. Error: ' + e.message);
		}
	}
};
