import {useCallback, useState} from 'react'
import {checkExceptionDictionaryQueueName, prepareQueueName} from '../utils/helpers.js'

export const useEndpointsQueues = ({isSeparateName = false}) => {
    const [localEndpoints, setLocalEndpoints] = useState(null)

    const setEndpoints = useCallback((endpoints) => {
        if (isSeparateName) {
            const formattedEndpoints = endpoints.map(item => {
                if (checkExceptionDictionaryQueueName(item.name) || !item.name.includes('-')) {
                    return item
                }
                return {...item, name: prepareQueueName(item.name)}
            })

            setLocalEndpoints(formattedEndpoints)
        } else {
            setLocalEndpoints(endpoints)
        }

    }, [isSeparateName])

    return [localEndpoints, setEndpoints]
}
