import React, {useState} from "react";

import Dropdown from "react-bootstrap/Dropdown";

const Templates = ({setTemplate, queueName, commonAnswers: {queueTemplates},hideDropdown}) => {
	const [showDropdown, setShowDropdown] = useState(false);
	let listTemplates = queueTemplates[queueName];
	if (queueTemplates['all']) {
		listTemplates = (listTemplates) ? [...listTemplates, ...queueTemplates['all']] : queueTemplates['all'];
	}
	const toggleDropdown = () => setShowDropdown(!showDropdown)
	return (
		<Dropdown className='templates-toggle' onToggle={toggleDropdown} show={showDropdown && !hideDropdown}>
			<Dropdown.Toggle variant="link">
				<div>Templates</div><div className={"dropdown-icon"}/>
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{listTemplates &&
				listTemplates.map((item, key) => {
					return (
						<Dropdown.Item
							key={key}
							eventKey={item.text}
							onClick={() => setTemplate(item)}>
							<span className={item.underline?"underline-bold":""}>{key+1}. {item.title}</span>
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default Templates;