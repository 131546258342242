import {toast} from "react-toastify";
import Rodal from "rodal";
import React, {useState} from "react";
import ChatTransferNotify from "./ChatTransferNotify";
import PopupSpinner from "../PopupSpinner";

const TRANSFER_STATE_SPINNER = 0;
const TRANSFER_STATE_CONTENT = 1;
const TRANSFER_STATE_EMPTY_CONTENT = 2;
const TRANSFER_STATE_ERROR = 3;

const ChatTransferModalOld = (props) => {

    const {
        showPopup, setShowPopup,
        transferContentState,
        currentCustomerName,
        endpoints,
        changeQueue,
        showTransfer
    } = props;

    const [queueKey, setQueueKey] = useState(false);

    return <Rodal visible={showPopup}
                  onClose={() => {
                      setShowPopup(false)
                  }}
                  animation='slideDown'
                  width={680}
                  customStyles={{marginTop: '80px'}}
    >
        <h3 className="modal-transfer-head">Transfer Chat</h3>
        <hr className="modal-transfer-line"/>
        <div className="modal-data">
            {
                transferContentState === TRANSFER_STATE_SPINNER ? <PopupSpinner/> : ''
            }
            {
                transferContentState === TRANSFER_STATE_CONTENT ?
                    <>
                        <p>Transfer the chat with <b><u>{currentCustomerName}</u></b> to:</p>
                        {
                            endpoints && endpoints.length > 0 ?
                                endpoints.map((item, key) => {
                                    return item.showEndpoint === true ?
                                        <div key={key} onClick={() => setQueueKey(key)}>
                                            <input type='radio'
                                                   checked={queueKey === key}
                                                   style={{cursor: "pointer"}}
                                                   readOnly
                                            />
                                            <label style={{cursor: "pointer"}}>{item.name}</label>
                                        </div> : ''
                                }) : ''
                        }
                        <hr className="modal-transfer-bottom-line"/>
                        <div>
                            <button className="btn btn-info" onClick={() => {
                                changeQueue(queueKey);
                                setShowPopup(false);
                                toast(<ChatTransferNotify/>)
                            }}>Transfer Chat
                            </button>
                            <button className="btn btn-outline-info btn-cancel" onClick={() => {
                                setShowPopup(false)
                            }}>Cancel
                            </button>
                        </div>
                    </>
                    :
                    ''
            }
            {
                transferContentState === TRANSFER_STATE_EMPTY_CONTENT ?
                    <>
                        <p>No agents available.</p>
                        <div>
                            <button className="btn btn-outline-info btn-cancel" onClick={() => {
                                setShowPopup(false)
                            }}>Ok
                            </button>
                        </div>
                    </>
                    :
                    ''
            }
            {
                transferContentState === TRANSFER_STATE_ERROR ?
                    <>
                        <p>Can't get available agents.</p>
                        <div>
                            <button className="btn btn-outline-info btn-cancel" onClick={() => {
                                showTransfer()
                            }}>Retry
                            </button>
                            <button className="btn btn-outline-info btn-cancel" onClick={() => {
                                setShowPopup(false)
                            }}>Ok
                            </button>
                        </div>
                    </>
                    :
                    ''
            }
        </div>
    </Rodal>
}

export default ChatTransferModalOld;