import { useEffect, useState } from 'react';
import {AgentState} from "../utils/constant";

export const useAgentConfiguration = (status) => {
	const [agentConfiguration, setAgentConfiguration] = useState({});

	useEffect(() => {
		try {
			const statusName = status?.name;
			if (statusName && statusName.toLowerCase() !== AgentState.OFFLINE) {
				setAgentConfiguration(window.myCPP.agent.getConfiguration());
			}
		} catch (ex) {}
	}, [status]);

	return agentConfiguration
}