import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const DebugMenu = ({buttons}) => {
	return (
		<Dropdown>
			<Dropdown.Toggle
				size="sm"
				variant="secondary">
				Debug Menu
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{
					buttons && buttons.map((item, key) => {
						return (
							item.title === '---' ?
								<Dropdown.Divider
									key={key}
								/>
								:
								<Dropdown.Item
									key={key}
									onClick={item.onClick}>
									{item.title}
								</Dropdown.Item>
						);
					})
				}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default DebugMenu;