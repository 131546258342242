import React, {useEffect, useState} from 'react';
import style from './AgentStatus.module.scss'
import {ThreeDots as LoaderThreeDots} from "react-loader-spinner";
import {AgentState} from "../../utils/constant";

function AgentStatus({agentName, status, setAgentStatus}) {
    const getAgent = () => {
        return window.myCPP?.agent;
    }

    const getAgentStateByStatus = () => {
        let statusName = status?.name?.toLowerCase();
        const nextStatusName = getAgent()?.getNextState()?.name?.toLowerCase();
        if (nextStatusName) {
            statusName = nextStatusName;
        }
        if ([AgentState.OFFLINE, AgentState.AWAY].includes(statusName)) {
            return statusName;
        } else {
            return AgentState.ONLINE;
        }
    }

    const getAgentStatusName = (state) => {
        switch (getAgentStateByStatus()) {
            case AgentState.ONLINE:
                return "Online";
            case AgentState.AWAY:
                return "Away";
            case AgentState.OFFLINE:
                return "Offline";
            default:
                return "Offline";
        }
    }

    const checkStatus = (compareStatus) => {
        return getAgentStateByStatus() === compareStatus;
    }

    const switchAgentStatus = (event) => {
        event.preventDefault();
        if (checkStatus(AgentState.AWAY)) {
            return;
        }
        if (checkStatus(AgentState.OFFLINE)
            || checkStatus(AgentState.AWAY)
        ) {
            setAgentStatus(AgentState.ONLINE);
        } else {
            setAgentStatus(AgentState.OFFLINE);
        }
    };

    return (
        <div className={style["chat-status"]}>
            {
                !agentName ?
                    <div className={style["status-loader"]}>
                        <LoaderThreeDots
                            color="#d7d7d7"
                            height={60}
                            width={60}
                            timeout={0}
                        />
                    </div>
                    :
                    <div>
                        <div
                            className={"custom-control custom-switch custom-switch-lg " + getAgentStateByStatus()}
                            onClick={switchAgentStatus}>
                            <input type="checkbox"
                                   className="custom-control-input"
                                   id="agentStatusSwitch"
                                   checked={checkStatus(AgentState.OFFLINE)}
                                   readOnly
                            />
                            <label className="custom-control-label" htmlFor="agentStatusSwitch">
                                <span>{getAgentStatusName()}</span>
                            </label>
                        </div>
                        <div className={"agentAwaySwitch"}>
                            <input type="checkbox"
                                   className={"control-input"}
                                   id="agentAwaySwitch"
                                   checked={checkStatus(AgentState.AWAY)}
                                   onClick={() => setAgentStatus(checkStatus(AgentState.AWAY) ? AgentState.ONLINE : AgentState.AWAY)}
                            />
                            <label className="control-label" htmlFor="agentAwaySwitch">
                                <span>Set status as away</span>
                            </label>
                        </div>
                    </div>
            }
        </div>
    );
}

export default AgentStatus;