import React from "react";

export const PrescriptionMessage = (props) =>
{
    const {data, messageTime} = props;
    const {customerName, customerDOB, additionalData} = data;
    const prescriptionInfo = additionalData?.prescription;

    return <div className={"row message-left"}>
        <div className="col-md-9">
            {
                prescriptionInfo && prescriptionInfo.length ?
                    <p>
                        New Prescription Refill Request for {customerName} {customerDOB}: <br/>
                        {
                            prescriptionInfo.map((item, key)  => {
                                return <React.Fragment key={key}>
                                    <br/>
                                    Request #{key+1}<br/>
                                    Patient Medication Details: {item.patientMedicationDetails}<br/>
                                    Prescriber: {item.prescriber}<br/>
                                </React.Fragment>;
                            })
                        }
                    </p>
                    :
                    <p>
                        This is an existing Refill Status Check for {customerName} {customerDOB}.
                    </p>
            }
            <span className={"message-time "}>
                {messageTime}
            </span>
        </div>
        <div className="col-md-3">
        </div>
    </div>;
}
