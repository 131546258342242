import React from "react";
import Message from './Message';
import {normalizeDateTime, transformToChatStartMessageTime, transformToMessageTime} from '../../utils/transformers'
import {AlertErrors} from "../../utils/constant";
import styles from "./Messages.module.css"

const Messages = (props) => {
        let {contact, messages, agentName, isTyping, prescription, showAlert} = props;
        let transcriptData
        if (contact && contact?.attributes?.transcript) {
            try {
                transcriptData = JSON.parse(contact.attributes.transcript.value);
                let transcriptMessages = transcriptData
                    .filter(item => item?.Id && item?.AbsoluteTime)
                    .map((item) => {
                    return {
                        Content: item.Message,
                        AbsoluteTime: normalizeDateTime(item?.AbsoluteTime),
                        ParticipantRole: item.ParticipantRole,
                        Id:item.Id,
                        ContentType: "text/plain",
                    }
                })
                if (transcriptMessages) {
                    messages = [...transcriptMessages, ...messages]
                }
            } catch (e) {
                console.log(e);
            }
        }
    const filterCondition = (message) => {
        const exceptWords = [
            'LEX_BOT_ENTER_CHAT',
            'WAITING_AGENT',
        ];
        return !(
            exceptWords.indexOf(message.Content) !== -1
            || (message.Content && message.Content.indexOf('GET_INTENT_DESCRIPTION', 0) !== -1)
        );
    }

    const chatStartMessageTime = (message) => {
        if (!message) {
            showAlert(AlertErrors.COMMON);
            return '(unknown)';
        }
        return transformToChatStartMessageTime(normalizeDateTime(message.AbsoluteTime));
    }

    const messageTime = (message) => {
        if (!message) {
            showAlert(AlertErrors.COMMON);
            return '(unknown)';
        }
        return transformToMessageTime(normalizeDateTime(message.AbsoluteTime));
    }
    let firstTransferKey = false;
    return <div className="chat-messages-list" id="chat-messages-list">
        {
            messages.length > 0 ?
                <div key={messages[0].Id} className="row message-right">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-9 text-right">
                        <p className="text-uppercase">
                            Chat started on {chatStartMessageTime(messages[0])}
                        </p>
                        <span className="message-time">
                            {messageTime(messages[0])}
                        </span>
                    </div>
                </div> : ''
        }

        {
            messages.length > 0 ?
                messages.filter(message => filterCondition(message))
                    .map((currMessage, key, _messages)  => {
                        const nextMessage = _messages[key+1];
                        const showTime = (nextMessage === undefined
                                || (nextMessage &&
                                    (
                                        (currMessage.ParticipantRole === 'CUSTOMER' && nextMessage.ParticipantRole !== 'CUSTOMER')
                                        || (currMessage.ParticipantRole === 'CUSTOMER' && nextMessage.Type === 'EVENT')
                                        || (currMessage.ParticipantRole !== 'CUSTOMER' && nextMessage.ParticipantRole === 'CUSTOMER')
                                    )
                                )
                        );
                        if(currMessage.ContentType === 'application/vnd.amazonaws.connect.event.transfer.succeeded'){
                             firstTransferKey = key
                        }
                        const showTransferMessage = firstTransferKey ? key > firstTransferKey : false
                        return <Message key={currMessage.Id}
                                     contact={contact}
                                     message={currMessage}
                                     agentName={agentName}
                                     showTime={showTime}
                                     setToken={props.setToken}
                                     currentContact={props.currentContact}
                                     prescription={prescription}
                                     showTransferMessage={showTransferMessage}
                                />
                    }) : ''
        }
        {
            isTyping ? <Dots /> : ''
        }
        {
            contact?.state?.type === 'ended' ?
                <div style={{height: "80px", position: "relative"}}>
                    <div className={styles.chat_end_breaker} id="messages-chat-end-breaker">
                        Ended by {contact?.additionalData?.chatEndedByAgent ? 'Agent' : 'Customer'}
                    </div>
                </div> : ''
        }
    </div>
};

export default Messages;

const Dots = () => {
  return (
      <div className="row message-left typing">
          <div className="col-md-9">
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
          </div>
          <div className="col-md-3">
          </div>
      </div>
  );
};
