import { I18n } from 'i18n';
import { animateScroll } from 'react-scroll';
import {EXCEPTION_DICTIONARY} from './constant.js'

export const scrollToBottom = (isSmooth) => {
	if (isSmooth || isSmooth === undefined) {
		setTimeout(() => {
			animateScroll.scrollToBottom({
				containerId: "chat-messages-list",
			});
		}, 200);
	} else {
		animateScroll.scrollToBottom({
			containerId: "chat-messages-list",
			duration: 0,
		});
	}
};

export const isIn = (haystack, needle, offset) => {
	if (!haystack) {
		return false;
	}
	if (typeof haystack === 'object' && !Array.isArray(haystack)) {
		return haystack.hasOwnProperty(needle);
	} else {
		return haystack.indexOf(needle, offset) !== -1;
	}
}

export const removeFromArray = (array, name) => {
	if (!array || !name) {
		return;
	}
	try {
		const idx = array.indexOf(name);
		if (idx !== -1) {
			array.splice(idx, 1);
		}
	} catch (e) {}
}

export const removeFromObject = (object, name) => {
	if (!object || !name) {
		return;
	}
	try {
		delete object[name];
	} catch (e) {}
}

export const checkEmptyInputText = (text) => {
	if (!text) {
		return true;
	}
	return text.replace(/\s+/g, '') === '';
}

export const isDebugEnabled = () => {
	return new URLSearchParams(window.location.search).get("debug_enabled") === '1';
}

export const getDebugLoginUrl = () => {
	if (['DEVELOPMENT', 'STAGING'].includes(process.env.REACT_APP_STAGE)) {
		return new URLSearchParams(window.location.search).get("login_url");
	}
	return false;
}

export const isNewConfig = () => {
	return new URLSearchParams(window.location.search).get("new_config") === '1';
}

export const i18n = new I18n({
    locales: ['en', 'es'],
    staticCatalog: {
        es: require('../locales/es.json'),
        en: require('../locales/en.json'),
    },
    defaultLocale: 'en',
})

export const chunkSubstr = (str, size) => {
	const numChunks = Math.ceil(str.length / size);
	const chunks = new Array(numChunks);

	for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
		chunks[i] = str.substr(o, size);
	}

	return chunks;
}

export const prepareQueueName = name => name.split('-').slice(1).join('-')

export const checkExceptionDictionaryQueueName = name => EXCEPTION_DICTIONARY.includes(name)
