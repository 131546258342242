import React from "react";

export const NurseAdviceMessage = (props) => {
    const {data, messageTime} = props;
    const nurseAdviceInfo = data?.additionalData?.nurseAdvice;
    return <>
        {
            nurseAdviceInfo?.questions?.length ?
                <div className={"row message-left"}>
                    <div className="col-md-9">
                        <p>
                            New Nurse Advice Record Request: <br/>
                            {

                                <>
                                    What can we help you with today?: {nurseAdviceInfo.problem}<br/>
                                    {
                                       nurseAdviceInfo.questions.map((question)=>{
                                            return <div>{question.question}:{question.answer}</div>
                                        })
                                    }
                                </>
                            }
                        </p>
                        <span className={"message-time "}>
                            {messageTime}
                        </span>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
                :
                ''
        }
    </>;
}
