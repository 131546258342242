import React, {useState,useEffect} from "react";
import axios from "axios"

import {ThreeDots as LoaderThreeDots} from "react-loader-spinner";
import Rodal from 'rodal';
import Button from 'react-bootstrap/Button';
import {ToastContainer} from 'react-toastify';
import CustomAlert from './common/CustomAlert/CustomAlert';

import 'react-toastify/dist/ReactToastify.css'
import 'rodal/lib/rodal.css';
import {getTrimmedCustomerName} from "./Contacts/Contacts";
import {sendErrorLog} from "../utils/logger";
import { getContactQueueName } from "../services/Amazon/Connect";
import {ContactState} from "../utils/constant";
import ChatTransferModal from "./Modals/ChatTransfer/ChatTransferModal";
import {isDebugEnabled} from "../utils/helpers";
import AgentStatus from "./AgentStatus/AgentStatus";

const TRANSFER_STATE_SPINNER = 0;
const TRANSFER_STATE_CONTENT = 1;
const TRANSFER_STATE_EMPTY_CONTENT = 2;
const TRANSFER_STATE_ERROR = 3;

const Header = ({
                    agentChatConfiguration,
                    agentConfiguration,
                    status,
                    setAgentStatus,
                    contacts,
                    currentContact,
                    currentContactState,
                    agentName,
                    changeQueue,
                    endpoints,
                    setEndpoints,
                    showAlert,
                    showAlertError,
                    setShowAlertError,
                    reloadChat,
                    emptyMessages,
                    activeContacts,
                    onEndChat,
                    templateModalData,
                    setTemplateModalData,
                    setTemplateMessage,
                    replaceTemplateModalText,
                }) => {

    const [showPopup, setShowPopup] = useState(false);
    const [transferContentState, setTransferContentState] = useState(TRANSFER_STATE_SPINNER);
    const [alertToRefresh, setAlertToRefresh] = useState(false);
    const [templateValues, setTemplateValues] = useState({});
    const [canApplyTemplate, setCanApplyTemplate] = useState(false);

    const {instanceId} = agentChatConfiguration;
    const templateModalOriginalText = templateModalData.text

    useEffect(() => {
        if (templateModalData.parameters !== undefined && templateModalData.parameters.length === Object.keys(Object.entries(templateValues).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})).length) {
            setCanApplyTemplate(true)
        } else {
            setCanApplyTemplate(false)
        }
        setTemplateModalData((prevState) => {
            return ({
                ...prevState,
                htmlText:replaceTemplateModalText(templateModalData,templateValues)
            })
        },)
    }, [templateValues])
    const changeTemplateInput = (input) => (e) => {
        const value = e.target.value
        setTemplateValues((prevState => {
            return ({...prevState, [input]: value})
        }))

    }
    const closeModal = () =>{
        setTemplateValues({})
        setTemplateModalData((prevState) => {
            return ({
                ...prevState,
                show: false
            })
        })
    }
    const applyTemplateVariables = () => {
        let newText = templateModalData.text;
        Object.keys(templateValues).forEach((key) => {
            const value = templateValues[key]
            newText = newText.replace("{" + key + "}", value)
        })
        setTemplateMessage(newText,true)
        closeModal()
    }

    const onChatRefresh = () => {
        // document.location.reload();
        setAlertToRefresh(false);
        reloadChat();
    };

    const onShowRefreshAlert = () => {
        setShowAlertError(false);
        setAlertToRefresh(true);
    };

    const showTransfer = () => {
        setTransferContentState(TRANSFER_STATE_SPINNER);
        setShowPopup(true);

        axios.post(process.env.REACT_APP_AMAZON_API_URL + '/agent/metrics', {
                instanceId: instanceId,
                quickConnects: endpoints.map(endpoint => endpoint.endpointARN.split(':')[5].split('/')[3])
            },
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                let endpointsPersist = false;
                endpoints.forEach((endpoint, index) => {
                    const quickConnectId = endpoint?.endpointARN.split(':')[5].split('/')[3];
                    const queueMetrics = response?.data?.quickConnects[quickConnectId]?.queueMetrics;
                    if (queueMetrics) {
                        let availability = false;
                        /* // Old method
                        if (queueMetrics.slots) {
                            availability = queueMetrics.slots.available > 0;
                        } else {
                            availability = queueMetrics.agents.available > 0;
                        }
                        */
                        availability = (queueMetrics?.agents?.online || 0) > 0;
                        if (availability) {
                            endpoints[index].showEndpoint = true;
                            endpointsPersist = true;
                        } else {
                            endpoints[index].showEndpoint = false;
                        }
                    }
                });
                setEndpoints(endpoints);
                if (endpointsPersist) {
                    setTransferContentState(TRANSFER_STATE_CONTENT);
                } else {
                    setTransferContentState(TRANSFER_STATE_EMPTY_CONTENT);
                }
            })
            .catch((e) => {
                setTransferContentState(TRANSFER_STATE_ERROR);
                console.log(e)
                sendErrorLog('Show transfer endpoints failed', {message: e.message});
            });
    };


    const getDevelopmentLabel = (isQueueInfo = false) => {
        if (!isDebugEnabled()) {
            return;
        }
        if (!['DEVELOPMENT', 'STAGING'].includes(process.env.REACT_APP_STAGE)) {
            return;
        }
        try {
            const agentConfiguration = window.myCPP.agent.getConfiguration();
            if (isQueueInfo) {
                return <p className="label-dev-queue">{getContactQueueName(contacts[currentContact], agentChatConfiguration?.isSeparateName)}</p>;
            }
            switch (process.env.REACT_APP_STAGE) {
                case 'DEVELOPMENT':
                    return <div className="label-dev">DEVELOPMENT |
                        Agent: <b>{agentConfiguration.username} ({agentConfiguration.routingProfile.name})</b></div>
                case 'STAGING':
                    return <div className="label-dev staging">STAGING |
                        Agent: <b>{agentConfiguration.username} ({agentConfiguration.routingProfile.name})</b></div>
                default:
                    break;
            }
        } catch (e) {
        }
    }

    const getCurrentCustomerName = () => {
        return getTrimmedCustomerName(contacts[currentContact]?.attributes?.customerName, 22);
    };

    return (
        <div className="header-container row">
            {getDevelopmentLabel()}
            <div className="chat-search-wrapper">
            </div>
            <div className="switch-menu col-md-4">
                <div className="labels">
                    <div className="chat-list">Chat list</div>
                    <div className="active-chats">
                        {activeContacts} active
                    </div>
                </div>
                <AgentStatus agentName={agentName} status={status} setAgentStatus={setAgentStatus} />
            </div>

            <div className="col-md-8">
                {
                    contacts[currentContact] ?
                        <div className="row messages-header">
                            <div className="col-md-6 customer-name">
                                <h3>
                                    {getCurrentCustomerName()}
                                </h3>

                                <p>Queue: {getContactQueueName(contacts[currentContact], agentChatConfiguration?.isSeparateName)??"N/A"}</p>
                            </div>
                            <div className="col-md-6 end-chat">
                                {
                                    true /*currentContactState !== ContactState.TRANSFERRING*/ ?
                                        <>
                                            {
                                                ['connected', 'ended', 'missed', 'error'].indexOf(contacts[currentContact].state?.type) !== -1 ?
                                                    <button type="button"
                                                            className={"btn btn-small btn-end " + (
                                                                contacts[currentContact].state.type !== 'connected'
                                                                    ? 'btn-close' : ''
                                                            )}
                                                            onClick={() => onEndChat()}>
                                                        <i className="far fa-window-close" />
                                                        <p>
                                                            {
                                                                contacts[currentContact].state.type !== 'connected'
                                                                    ? 'Close Chat' : 'End Chat'
                                                            }
                                                        </p>
                                                    </button>
                                                    : ''
                                            }
                                            {
                                                endpoints && endpoints.length > 0 && contacts[currentContact].state?.type === 'connected' ?
                                                    <button type="button" className={"btn btn-small btn-transfer"}
                                                            onClick={() => {
                                                                showTransfer();
                                                            }}>
                                                        <i className="fas fa-exchange-alt" />
                                                        <p>Transfer</p>
                                                    </button>
                                                    : ''
                                            }
                                        </>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                        :
                        emptyMessages && <div className="no-chats">No message selected</div>
                }
            </div>

            <ChatTransferModal
                newVersion={true}
                agentChatConfiguration={agentChatConfiguration}
                agentConfiguration={agentConfiguration}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                transferContentState={transferContentState}
                currentCustomerName={getCurrentCustomerName()}
                endpoints={endpoints}
                changeQueue={changeQueue}
                showTransfer={showTransfer}
                contacts={contacts}
                currentContact={currentContact}
                config={{
                    xApiUrl: agentChatConfiguration.xApiUrl,
                    xApiKey: agentChatConfiguration.xApiKey
                }}
                queueName={getContactQueueName(contacts[currentContact], agentChatConfiguration?.isSeparateName)}
            />

            <Rodal visible={templateModalData.show}
                   onClose={closeModal}
                   animation='slideDown'
                   width={680}
                   className={'templates-modal'}
                   customStyles={{marginTop: '80px'}}
            >
                <h3 className="modal-transfer-head">{templateModalData.title}</h3>
                <hr className="modal-transfer-line"/>
                <div className="modal-data">
                    {
                        templateModalData.parameters ?
                            <>
                                <div dangerouslySetInnerHTML={{__html: templateModalData.htmlText}}/>
                                <div style={{margin: "20px 0 10px 0 "}}>Please enter values</div>
                                <div>{templateModalData.parameters.map((item) => {
                                    return <div className={"row"}>
                                        <div className={"col-md-5"}>
                                            <div className={"form-group"}>
                                                <input className={"form-control"}
                                                       placeholder={item.title}
                                                       value={templateValues[item.variable]||""}
                                                       onChange={changeTemplateInput(item.variable)}/></div>
                                        </div>
                                    </div>
                                })}</div>
                            </>
                            : ''
                    }
                    <div className={"text-right"}>
                        <button className={"btn btn-small btn-outline-btn"} onClick={closeModal}>Cancel</button>
                        <button className={"btn btn-small btn-outline-btn apply " + (!canApplyTemplate ? "disabled" : "")}
                                onClick={applyTemplateVariables} disabled={!canApplyTemplate}>Apply
                            Template
                        </button>
                    </div>
                </div>
            </Rodal>
            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
            <CustomAlert
                text={'Are you sure you want to reload the chat?'}
                show={alertToRefresh}
                variant={'secondary'}
                setShow={setAlertToRefresh}>
                <Button
                    onClick={onChatRefresh}
                    variant="success">
                    Yes
                </Button>
                <Button
                    onClick={() => setAlertToRefresh(false)}
                    variant="danger">
                    No
                </Button>
            </CustomAlert>
        </div>
    )
};

export default Header;

