import React from 'react';
import Modal from 'react-bootstrap/Modal';
import style from './CustomModal.module.css'

function CustomModal({
	show,
	setShow,
	children,
}) {

	const onHide = () => {
		setShow(false);
	}

	return (
		<>
			<Modal
				show={show}
				onHide={onHide}
				dialogClassName={style.container}
			>
				{children}
			</Modal>
		</>
	);
}
export default CustomModal