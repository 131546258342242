export const transformToSelect = (array) => {
	const options = [];
	array.forEach((item, index) => {
		options.push({
			value: item.value ? item.value : item.name,
			label: `${index + 1}. ${item.name}`,
			id: item.id ? item.id : item.id
		});

	});
	return options
};

export const transformToMessageTime = (fromTime) => {
	let result = '(unknown)';
	if (fromTime) {
		try {
			const locale = 'en-GB';
			const dt = new Date(fromTime);
			result = new Intl.DateTimeFormat(locale, {
				hour: 'numeric',
				minute: '2-digit',
				hc: 'h12',
				hourCycle: 'h12'
			}).format(dt).toUpperCase();
		} catch (e) {}
	}
	return result;
}

export const transformToChatStartMessageTime = (fromTime) => {
	let result = '(unknown)';
	if (fromTime) {
		try {
			const locale = 'en-GB';
			const dt = new Date(fromTime);
			const month = new Intl.DateTimeFormat(locale, {month: 'short'}).format(dt);
			const day = new Intl.DateTimeFormat(locale, {day: '2-digit'}).format(dt);
			const year = new Intl.DateTimeFormat(locale, {year: 'numeric'}).format(dt);
			const hm = new Intl.DateTimeFormat(locale, {
				hour: 'numeric',
				minute: '2-digit',
				hc: 'h12',
				hourCycle: 'h12'
			}).format(dt).toUpperCase();
			result = month + ' ' + day + ', ' + year + ' AT ' + hm;
		} catch (e) {}
	}
	return result;
}

export const transformURLToLinkInText = (text) => {
	const urlPattern = /(^|\s)(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;
	if(text.includes('\n')){
		text = text.replace(/\n/g,'<br>')
	}
	return text.replace(urlPattern, '<a target="_blank" href="$&">$&</a>');
}

export const normalizeDateTime = datetimeStr => {
	return datetimeStr.includes('T') && datetimeStr.endsWith('Z')
		? datetimeStr
		: datetimeStr.replace(/ /g, 'T') + 'Z'
}
