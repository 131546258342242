export const CHAT_CATEGORY_INPUT = 'input';

export const AppState = {
    NORMAL: 0,
    LOGIN: 1,
    RELOAD: 2,
    ERROR: 3
};

export const ContactState = {
    NORMAL: 0,
    LOADING: 1,
    CONNECTING: 2,
    TRANSFERRING: 3
};

/* This states are internal in this component.
   They can be different from Connect states.
*/
export const AgentState = {
    ONLINE: 'online',
    OFFLINE: 'offline',
    AWAY: 'away',
};

export const AlertErrors = {
    COMMON: {
        title: 'You got an error!',
        text: 'Something went wrong, the page will be reloaded.',
    },
    NETWORK: {
        title: 'Network Connection Error!',
        text: 'Please check your internet and/or VPN connection',
    },
};

export const InfoMessages = {
    CANT_GO_OFFLINE: {
        title: 'Active chat(s) in progress',
        text: 'End all active chat conversations before you change your status to offline.',
        showCloseButton: true,
    },
    CHAT_MISSED_OR_REJECTED: {
        title: 'New chat requests temporarily paused',
        text: 'You missed or rejected a chat. You will not receive new messages until the missed or rejected chat is removed.',
        showCloseButton: false,
    },
    AFTER_REFRESH_ON_NETWORK_ERROR: {
        title: 'Network Connection error occurred',
        text: 'The page has been automatically updated.',
        showCloseButton: true,
    }
};

export const TIMEOUT_TYPING_EVENT = 30000;
export const TIMEOUT_AGENT_CHAT_RELOAD_EVENT = 3000;
export const TIMEOUT_AGENT_CHAT_PAGE_RELOAD_EVENT = 1500;

export const QUEUE_TYPE_DEFAULT = 0;
export const QUEUE_TYPE_PRESCRIPTION_REFILL_REQUEST = 1;
export const QUEUE_TYPE_STANDALONE_CHAT = 2;
export const QUEUE_TYPE_SCHEDULE_AN_APPOINTMENT = 3;
export const QUEUE_TYPE_GI = 4;
export const QUEUE_TYPE_RN = 5; // Nurse Advice
export const QUEUE_TYPE_BILLING = 6;
export const QUEUE_TYPE_IMMUNIZATION_RECORD_REQUEST = 7;

// Base
export const QUEUE_NAME_BILLING = 'Billing';
export const QUEUE_NAME_BILLING_ES = 'Spanish Billing';

// Bon Secours & Mercy Health
export const QUEUE_NAME_BSMH_BS_CUSTOMER_SERVICE = 'Bon Secours - CS';
export const QUEUE_NAME_BSMH_BS_ORDERS = 'Bon Secours - Orders';
export const QUEUE_NAME_BSMH_MH_CUSTOMER_SERVICE = 'Mercy - CS';
export const QUEUE_NAME_BSMH_MH_ORDERS = 'Mercy - Orders';
export const QUEUE_NAME_BSMH_ESCALATIONS = 'BSMH - CS - Escalations';

// Scripps
export const QUEUE_NAME_SCRIPPS_1_800 = 'Scripps - 1-800 Scripps';
export const QUEUE_NAME_SCRIPPS_ESTIMATES = 'Scripps - Estimates';

export const QUEUE_NAME_NCH_CUSTOMER_SERVICE = 'NCH - CS';
export const QUEUE_NAME_FARFIELD_CUSTOMER_SERVICE = 'Fairfield - CS';
export const QUEUE_NAME_NICLAUS_CUSTOMER_SERVICE = 'Nicklaus - CS';

// Ballad & Roper
export const QUEUE_NAME_BALLAD_CUSTOMER_SERVICE = 'Ballad - CS';
export const QUEUE_NAME_ROPER_CUSTOMER_SERVICE = 'Roper - CS';

//BH
export const QUEUE_NAME_BH = 'Boone - CS';

//AHS
export const QUEUE_NAME_AHS = 'Adena - CS';

//CH
export const QUEUE_NAME_COVENANT_HEALTH_CUSTOMER_SERVICE = 'Covenant - CS';

//TCHHN
export const QUEUE_NAME_CHRIST_HOSPITAL_CUSTOMER_SERVICE = 'Christ - CS';

//TELEVOX
export const QUEUE_NAME_MAYO = 'Mayo';
export const QUEUE_NAME_BASIC = 'BasicQueue';

//RN
export const QUEUE_NAME_RN = 'RN';

export const QUEUE_NAME_FLOYD_RADIOLOGY = 'Floyd Radiology';

export const QueueIncomingLabel = {
    byType: {
        // Common
        [QUEUE_TYPE_DEFAULT]: 'Incoming chat from website',
        [QUEUE_TYPE_PRESCRIPTION_REFILL_REQUEST]: 'Incoming Prescription Refill Request',
        [QUEUE_TYPE_STANDALONE_CHAT]: 'Incoming Referrals Chat',
        [QUEUE_TYPE_SCHEDULE_AN_APPOINTMENT]: 'Incoming Schedule Help',
        [QUEUE_TYPE_GI]: 'Incoming GI Prep',
        [QUEUE_TYPE_RN]: 'Incoming Nurse Advice Chat from MyAtrium',
        [QUEUE_TYPE_BILLING]: 'Incoming Billing Chat from Link',
        [QUEUE_TYPE_IMMUNIZATION_RECORD_REQUEST]: 'Incoming Immunization Record Request',
    },
    byName: {
        // (BSMH) Bon Secours & Mercy Health
        [QUEUE_NAME_BSMH_BS_ORDERS]: 'Incoming chat from Odeza Bon Secours orders outreach',
        [QUEUE_NAME_BSMH_MH_ORDERS]: 'Incoming chat from Odeza Mercy Health orders outreach',
            // NCH
        [QUEUE_NAME_NCH_CUSTOMER_SERVICE]: 'Incoming chat from NCH website',
        // FMC
        [QUEUE_NAME_FARFIELD_CUSTOMER_SERVICE]: 'Incoming chat from Fairfield website',
        // NiCH
        [QUEUE_NAME_NICLAUS_CUSTOMER_SERVICE]: 'Incoming chat from Nicklaus website',
        // Ballad & Roper
        [QUEUE_NAME_BALLAD_CUSTOMER_SERVICE]: 'Incoming chat from Ballad website',
        [QUEUE_NAME_ROPER_CUSTOMER_SERVICE]: 'Incoming chat from Roper website',
        // AHS
        [QUEUE_NAME_AHS]: 'Incoming chat from Adena website',
        // BH
        [QUEUE_NAME_BH]: 'Incoming chat from Boone website',
        // TCHHN
        [QUEUE_NAME_CHRIST_HOSPITAL_CUSTOMER_SERVICE]: 'Incoming chat from Christ Hospital website',
        // CH
        [QUEUE_NAME_COVENANT_HEALTH_CUSTOMER_SERVICE]: 'Incoming chat from Covenant website',
    },
    byStartName: { // label for transferred chat, if started from this queue name
        // (BSMH) Bon Secours & Mercy Health
        [QUEUE_NAME_BSMH_BS_CUSTOMER_SERVICE]: 'Incoming chat from Bon Secours - Customer Service Queue',
        [QUEUE_NAME_BSMH_MH_CUSTOMER_SERVICE]: 'Incoming chat from Mercy Health - Customer Service Queue',
    }
};

export const EXCEPTION_DICTIONARY = ['TestQueue-1']
