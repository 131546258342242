import React from "react";
import {ContactState} from "../utils/constant";
import {Oval as LoaderOval} from "react-loader-spinner";

const LoadTranscripts = (props) => {

    const {
        currentContact,
        currentContactState,
        contacts,
        messages,
        loadTranscript,
        loadMoreTranscripts
    } = props;

    return (
        <>
            {
                loadMoreTranscripts[currentContact]?.load &&
                <div className={"transcript-load-more"}>
                    {
                        currentContactState === ContactState.LOADING ?
                            <LoaderOval
                                color="#d7d7d7"
                                height={60}
                                width={60}
                                timeout={0}
                            />
                            :
                            <div>
                                {/*
                                {
                                    loadMoreTranscripts[currentContact]?.failed &&
                                    <>
                                        If transcript is not loaded please try later, past 1 minute.<br/>
                                    </>
                                }
                                <button
                                    className="btn btn-outline-secondary"
                                    onClick={() => loadTranscript(currentContact, 1)}>
                                    Load more
                                </button>
                                */}
                                <>
                                    Previous transcript is unavailable.<br/>
                                </>
                            </div>
                    }
                </div>
            }
            {
                contacts[currentContact]
                && contacts[currentContact]?.state?.type === 'ended'
                && !messages[currentContact] &&
                <div className={"transcript-load"}>
                    {
                        currentContactState === ContactState.LOADING ?
                            <LoaderOval
                                color="#d7d7d7"
                                height={60}
                                width={60}
                                timeout={0}
                            />
                            :
                            <div>
                                Unable to load chat transcript.<br/>
                                Please try later, past 15 seconds.<br/>
                                <button
                                    className="btn btn-outline-secondary"
                                    onClick={() => loadTranscript(currentContact)}>
                                    Load transcript
                                </button>
                            </div>
                    }
                </div>
            }
        </>
    );
}

export default LoadTranscripts;